.tradeContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 24px;
	margin-top: 100px;

	@media only screen and (max-width: 1200px) {
		margin-top: 0px;
		flex-direction: column;
		padding: 16px;
		align-items: flex-start;
		& > * {
			width: 100%;
		}
	}
}
.tradeContainerPro {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
	padding: 0px 16px 0px 16px;
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		padding: 16px;
	}
	& > :first-child {
		@media only screen and (max-width: 1200px) {
			height: fit-content;
		}
		height: calc(100vh - 200px);
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 300px;
	}
	& > * {
		min-height: 500px;
		@media only screen and (max-width: 1200px) {
			min-height: 0px;
		}
	}
}

.sidePairList {
	flex: 1;
}

.tvContainer {
	position: relative;
	flex: 1;
	height: calc(100vh - 200px);
	min-height: 500px;
	& > :last-child {
		overflow: hidden;
	}
}

.tvCaution {
	color: #5757f7;
	font-size: 16;
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 4px;
	position: absolute;
	top: 10px;
	right: 100px;
	z-index: 10;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
}

.tvCautionContent {
	padding: 12px;
	width: 270px;
}
