.luckyWheelContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 40px;
	padding-bottom: 120px;
	& > :first-child {
		width: 100%;
		position: relative;
		background-image: url(../../assets//images/bg/lucky-wheel-bg.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row-reverse;
		padding: 0px 200px 0px 50px;

		@media only screen and (max-width: 1600px) {
			flex-direction: column;
			align-items: center;
			padding: 16px;
			gap: 100px;
		}

		& > :first-child {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 100vh;
			@media only screen and (max-width: 1200px) {
				width: 100%;
				justify-content: flex-start;
			}

			& > :nth-child(2) {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 100px 0px 100px 0px;

				@media only screen and (max-width: 1600px) {
					transform: scale(0.8);
					margin: 50px 0px 50px 0px;
				}
				@media only screen and (max-width: 1200px) {
					transform: scale(0.7);
					margin: 0px;
				}
				@media only screen and (max-width: 800px) {
					transform: scale(0.6);
				}
			}
			& > :nth-child(3) {
				z-index: 20;
				background: #5757f7;
				width: fit-content;
				height: fit-content;
				padding: 12px 24px;

				font-weight: 600;
				line-height: 36px;

				color: #fdfcff;
				@media only screen and (max-width: 1200px) {
					margin-top: 0px;
				}
			}
		}
		// height: max-content;
	}
}

.luckyWheel {
	width: 500px;
	height: 500px;
	position: relative;
	border-radius: 50%;
	overflow: hidden !important;
	transform: translateZ(0);
	z-index: 0;
	will-change: transform;
	background: gray;
	@for $i from 1 through 12 {
		& > :nth-child(#{$i}) {
			transform: rotate((($i * 30deg)-30deg));
			top: calc(250px - calc(250px * sin(16deg)));
			left: 250px;
			clip-path: polygon(100% 0, 0 50%, 100% 100%);
		}
	}
	// transition: all 10s cubic-bezier(0.2, 0, 0.15, 1);
}

.prizeLabel {
	width: 250px;
	height: calc(250px * 2 * sin(16deg));

	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	transform-origin: left center;
	padding-left: 100px;
	margin: 0;
	background: white;
	font-weight: 600;
	color: black;

	&:nth-of-type(even) {
		background: #7070f7;
		color: white;
	}
}

.spinButton {
	width: 100px;
	height: 100px;
	background: #fcd224;
	border-radius: 50%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: none;
	font-weight: 600px;
	border: 5px solid black;
	&:disabled {
		cursor: not-allowed;
	}
	font-size: 20px;
	font-weight: 600;
}
.arrow {
	position: absolute;
	transform: translateX(265px);
}

.powerBar {
	position: absolute;
	width: 500px;
	height: 16px;
	border-radius: 5px;
	background-color: white;
	transition: all 0.1s ease;
	overflow: hidden;
	position: absolute;
	transform: translateY(300px);
	border: 2px solid white;
	opacity: 0.9;
	max-width: 80vw;

	& > :first-child {
		border-radius: 5px;
		width: 0%;
		height: 100%;
		background-color: #7070f7;
	}
}

.wheel {
	position: absolute;
	width: 900px;
	transform: translate(-2px, -12px);
}

.head {
	font-weight: 700;
	font-size: 40px;
	color: #fdfcff;
	margin-bottom: 16px;
	text-align: center;
	width: 100%;
	position: relative;
	z-index: 10;
	@media only screen and (max-width: 1600px) {
		font-size: 32px;
	}
}
