.liteHead {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
	& > * {
		display: flex;
		align-items: center;
		gap: 12px;
	}
	& > :first-child {
		& > :nth-child(2) {
			font-weight: 600;
			font-size: 16px;
		}
	}
	& > :nth-child(2) {
		font-weight: 600;
		font-size: 16px;
		color: #000000;
	}
	@media only screen and (max-width: 1200px) {
		align-items: flex-start;
		width: 100%;
		& > :first-child {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 8px;
		}
	}
}

.liteChart {
	width: 776px;
	height: 450px;
	overflow: hidden;
	border-radius: 20px;
	padding-top: 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	& > :nth-child(2) {
		width: 100%;
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		height: 500px;
	}
}

.chartFooterUp {
	height: 100px;
	width: 100%;
	background-color: #d5f2dd;
}

.chartFooterDown {
	height: 100px;
	width: 100%;
	background-color: #fbe5e7;
}

.tabs {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	& > :first-child {
		& > * {
			width: fit-content;
		}
	}
}

.customTab {
	& > * {
		& > :first-child {
			margin-right: 8px;
			font-weight: 400;
			font-size: 14px;
		}
	}
	& > .nav-link.active {
		background-color: #5757f7;
	}
}

// .tradeComponent {
// 	width: 334px;
// }

.tradeTab {
	margin-bottom: 16px;
	display: flex;
	gap: 12px;
	& > * {
		width: 80px;
		height: 32px;
		font-weight: 600;
		font-size: 16px;
		color: #939393;
		cursor: pointer;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		@media only screen and (max-width: 1200px) {
			width: 50%;
		}
	}
}

.activeBuy {
	background: #2ebd56;
	color: #fdfcff;
}

.activeSell {
	background: #dd4c56;
	color: #fdfcff;
}
.tradeComponent {
	width: 300px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding-bottom: 48px;
	}
}
.trade {
	width: 300px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	& > :first-child {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
}

.inputSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	width: 100%;
}

.inputContainer {
	border: 1px solid #1c1a1f;
	border-radius: 12px;
	// padding: 8px 12px;
	display: flex;
	align-items: center;
	width: 100%;
	overflow: hidden;
	&:hover {
		border-color: #bcbcfb;
		& > * {
			border-color: #bcbcfb;
		}
	}
}

.customInput {
	height: 40px;
	width: 100%;
	outline: none;
	border: none;
	border-left: 1px solid #1c1a1f;
	border-right: 1px solid #1c1a1f;
	text-align: center;
	padding: 0px 12px;
	font-size: 14px;
	&:disabled {
		background-color: #939393;
		cursor: not-allowed;
	}
}

.customInputTotal {
	height: 40px;
	width: 100%;
	outline: none;
	border: none;
	text-align: end;
	padding: 0px 12px;
	font-size: 14px;
	&:disabled {
		background-color: #d3d3d3;
		cursor: not-allowed;
	}
}

.iconBtn {
	cursor: pointer;
	min-width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.selectType {
	width: 125px;
	height: 32px;
	padding: 6px 12px;
	border-radius: 12px;
	border: none;
	&:focus {
		outline: none;
	}
}
// .customOption {
// 	padding: 6px 12px;
// }

.selectContainer {
	border-radius: 12px;
	border: 1px solid #1c1a1f;
}

.rangeSelect {
	& > :first-child {
		font-weight: 400;
		font-size: 14px;
		color: #939393;
		position: relative;
		& > :first-child {
			font-weight: 400;
			font-size: 12px;
			color: #6f6f6f;
		}
	}
}

.customSliderStep {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.slider {
	margin-top: 6px;
	margin-bottom: 4px;
	-webkit-appearance: none;
	width: 100%;
	height: 3px;
	background-color: #bcbcfb;
	outline: none;
	position: absolute;
	top: 21px;
	left: 0px;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 12px;
	height: 12px;
	background: #5757f7;
	cursor: pointer;
	border-radius: 50%;
}

.slider::-moz-range-thumb {
	width: 12px;
	height: 12px;
	background: #5757f7;
	cursor: pointer;
	border-radius: 50%;
}

.total {
	& > :first-child {
		& > :nth-child(3) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			font-size: 12px;

			color: #6f6f6f;
			& > :nth-child(2) {
				font-weight: 600;
				color: #6f6f6f;
			}
		}
	}
}

.buyBtn {
	height: 35px;
	width: 100%;
	background: #2ebd56;
	border: 1px solid #000;
	border-radius: 22px;
	box-shadow: rgba(16, 6, 36, 0.6) 4px 4px 0 0;
	color: #fdfcff;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	font-weight: 600;
	font-size: 16px;
}

.buyBtn:active {
	box-shadow: rgba(16, 6, 36, 0.6) 2px 2px 0 0;
	transform: translate(2px, 2px);
}

.sellBtn {
	height: 35px;
	width: 100%;
	background: #dd4c56;
	border: 1px solid #000;
	border-radius: 22px;
	box-shadow: rgba(16, 6, 36, 0.6) 4px 4px 0 0;
	color: #fdfcff;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	font-weight: 600;
	font-size: 16px;
}

.sellBtn:active {
	box-shadow: rgba(16, 6, 36, 0.6) 2px 2px 0 0;
	transform: translate(2px, 2px);
}

.selectPairModal {
	& > :first-child {
		& > :first-child {
			& > :first-child {
				border: none;
				padding-bottom: 8px;
			}

			& > :nth-child(2) {
				width: 504px;
				margin-top: 0px;
			}
		}
	}
}

.searchPair {
	display: flex;
	gap: 6px;
	width: 100%;
}

.pairTabs {
	& > * {
		& > :first-child {
			margin-right: 8px;
		}
	}

	@media only screen and (max-width: 1200px) {
		justify-content: flex-start;
		& > * {
			width: fit-content;

			& > :first-child {
				margin-right: 0px;
			}
		}
	}
}

.pairsContainer {
	overflow-y: scroll;
	margin-top: 16px;
	height: calc(100vh - 180px - 524px);
}

.history {
	margin: auto;
	margin-top: 80px;
	width: 1325px;
	max-width: 100%;
	padding: 0px 28px;
	& > :first-child {
		font-weight: 600;
		font-size: 24px;
		color: #6f6f6f;
		margin-bottom: 16px;
	}
	@media only screen and (max-width: 1200px) {
		margin-top: 0px;
		padding: 24px 16px;
		& > :first-child {
			font-size: 16px;
			margin-bottom: 8px;
		}
		padding-bottom: 100px;
	}
}

.historyTabs {
	& > * {
		& > :first-child {
			margin-right: 24px;
		}
	}
	margin-bottom: 24px;

	@media only screen and (max-width: 1200px) {
		& > * {
			width: fit-content;
			max-width: calc(100% / 3);
			& > :first-child {
				margin-right: 0px;
				width: fit-content;
				font-size: 14px;
				padding: 6px 12px;
			}
		}
		margin-bottom: 16px;
	}
}

.buyBadge {
	width: 51px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0.5px solid #2ebd56;
	background: #d5f2dd;
	border-radius: 20px;
	font-weight: 400;
	font-size: 14px;
	color: #2ebd56;
}

.sellBadge {
	width: 51px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0.5px solid #dd4c56;
	background: #fbe5e7;
	border-radius: 20px;
	font-weight: 400;
	font-size: 14px;
	color: #dd4c56;
}

.actionBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 28px;
	padding: 4px 12px;
	background: #5757f7;
	border-radius: 14px;
	color: #fdfcff;
	cursor: pointer;
}
.historyTable {
	.rdt_Table {
		background-color: #5757f7;
	}
}

.proHead {
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #000;
	height: 80px;
	margin-bottom: 20px;
	& > * {
		height: 100%;
		display: flex;
		align-items: center;
	}

	& > :nth-child(2) {
		border-left: 1px solid #000;
		border-right: 1px solid #000;
		padding: 16px;
		width: 888px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		& > :first-child {
			font-weight: 600;
			font-size: 20px;
			color: #1b191d;
		}
	}
	& > :nth-child(3) {
		font-weight: 600;
		font-size: 16px;
		color: #000000;
		width: 232px;
		justify-content: center;
	}
}

.change24hItem {
	font-weight: 400;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-width: 160px;
	color: #1b191d;
	overflow: hidden;
	& > :first-child {
		color: #6f6f6f;
	}
}

.orderbook {
	padding: 16px 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 300px;
	& > :nth-child(2) {
		font-weight: 600;
		font-size: 20px;
		height: 10%;
		display: flex;
		align-items: center;
	}
}

.tradeTypeHead {
	display: flex;
	align-items: center;
	gap: 4px;
}

.changePairBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding: 22px 32px;
	min-width: 320px;
	color: #1b191d;

	& > :first-child {
		font-weight: 600;
		font-size: 24px;
	}
	width: fit-content;
	height: fit-content;
	padding: 8px;
	border: none;
	background-color: transparent;
	&:hover {
		color: #5757f7;
	}
}

.changePairBtnLite {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	color: #1b191d;
	& > :first-child {
		font-weight: 600;
		font-size: 24px;
	}
	width: fit-content;
	height: fit-content;
	border: none;
	background-color: transparent;
	&:hover {
		color: #5757f7;
	}
	@media only screen and (max-width: 1200px) {
		padding: 0;
	}
}

.slideOption {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #bcbcfb;
	&:hover {
		background-color: #5757f7;
	}
}

.slideOptionPassed {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #5757f7;
}

.percentSelectContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > * {
		width: 20%;
	}
	& > :nth-child(3) {
		text-align: center;
	}
	& > :nth-child(4),
	:nth-child(5) {
		text-align: end;
	}
}
.percentSelect {
	color: #bcbcfb;
	&:hover {
		color: #5757f7;
	}
	cursor: pointer;
}

.percentSelectActive {
	color: #5757f7;
	cursor: pointer;
}

.slideBar {
	position: absolute;
	top: 27px;
	height: 3px;
	width: 0%;
	z-index: 9999;
	background: #5757f7;
	pointer-events: none;
}

.mobileTradeButtonsContainer {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 16px;
	& > * {
		width: calc(50% - 8px);
	}
}

.mobileHistoryItem {
	& > :first-child {
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 16px;
		& > :first-child {
			color: #1b191d;
		}
	}
	& > :nth-child(2) {
		display: flex;
		flex-direction: column;
		gap: 4px;

		& > * {
			display: flex;
			justify-content: space-between;
			font-weight: 400;
			font-size: 12px;
			color: #939393;
			& > :nth-child(2) {
				color: #424243;
			}
		}
	}
	padding-bottom: 12px;
	padding-top: 12px;
	border-bottom: 1px solid #dedde0;
}
