.rewardModal {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 24px;
	@media only screen and (max-width: 1200px) {
		padding-bottom: 40px;
	}
}

.invite {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 1176px;
	& > :nth-child(2) {
		width: 512px;
		@media only screen and (max-width: 1200px) {
			width: 100%;
		}
		& > :first-child {
			padding: 16px 24px;
			font-size: 24px;
			font-weight: 600;
			color: #1b191d;
			border-bottom: 1px solid #1b191d;
		}
		& > :nth-child(2) {
			padding: 24px;
		}
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		flex-direction: column;
		gap: 20px;
		padding: 16px;
	}
}

.inviteContent {
	display: flex;
	flex-direction: column;
	gap: 24px;
	& > * {
		& > :first-child {
			font-size: 20px;
			color: #1b191d;
			margin-bottom: 8px;
		}
		& > :nth-child(2) {
			font-size: 40px;
			color: #000000;
			font-weight: 700;
		}
	}
	& > :nth-child(1) {
		& > :nth-child(2) {
			font-size: 16px;
			font-weight: 400;
			color: #1b191d;
			border: 1px solid #1c1a1f;
			border-radius: 12px;
			padding: 8px;
		}
	}
}
.guide {
	width: 1176px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding: 16px;
	}
}

.guideContent {
	padding-top: 28px;
	padding-bottom: 28px;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 40px;

	@media only screen and (max-width: 1200px) {
		grid-template-columns: repeat(1, 1fr);
	}
	& > * {
		display: flex;
		flex-direction: column;
		gap: 16px;
		& > :first-child {
			display: flex;
			align-items: center;
			gap: 24px;
			color: #1b191d;
			font-size: 24px;
			font-weight: 600;
			& > :first-child {
				font-size: 40px;
				font-weight: 700;
			}
		}
	}
}

.rewardIntro {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px;
	& > * {
		display: flex;
		align-items: center;
		gap: 16px;
	}
}

.myPrizes {
	width: 1176px;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		padding: 16px;
	}
}

.tasks {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 120px 20px;
	@media only screen and (max-width: 800px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (max-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
	}
	& > * {
		min-height: 200px;
		background: #fabdff;
		position: relative;
		border-radius: 10px;
		padding: 24px;
		color: #1b191d;
		font-size: 20px;
		font-weight: 600;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;

		& > :first-child {
			max-width: 50%;
			position: absolute;
			top: -100px;
			right: 12px;
			@media only screen and (max-width: 800px) {
				width: 40%;
				top: -50px;
			}
		}
		& > :nth-child(2) {
			position: relative;
			width: fit-content;
			& > :nth-child(2) {
				position: absolute;
				top: 50%;
				right: 50%;
				transform: translate(50%, -50%);
				font-weight: 700;
				font-size: 40px;
				color: #5757f6;
			}
		}
	}
}

.tasksContainer {
	max-width: 50%;
	@media only screen and (max-width: 1600px) {
		width: 100%;
		max-width: 100%;
		padding: 16px;
	}

	& > :first-child {
		font-weight: 700;
		font-size: 32px;
		color: #fdfcff;
		margin-bottom: 120px;
		text-align: center;
	}
}

.rewardToken {
	display: flex;
	align-items: center;
	gap: 8px;
	& > :first-child {
		border: 2px solid black;
		border-radius: 50%;
	}
}

.rewardAvatar {
	display: flex;
	align-items: center;
	gap: 8px;

	& > :first-child {
		border: 2px solid black;
		border-radius: 50%;
	}

	& > :nth-child(2) {
		display: flex;
		flex-direction: column;
		text-align: center;
	}
}

.rewardPoint {
	display: flex;
	align-items: center;
	gap: 8px;

	& > :nth-child(2) {
		font-size: 20px;
		font-weight: 600;
		color: #ee2a7b;
	}
}

.rewardChance {
	display: flex;
	align-items: center;
	gap: 8px;

	& > :nth-child(1) {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		border: 2px solid black;
		background-color: #69f73e;
		color: black;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
	}
	& > :nth-child(2) {
		display: flex;
		flex-direction: column;
		text-align: center;
	}
}

.rewardModalDetail {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	font-size: 20px;
	font-weight: 600;
	& > * {
		text-align: center;
	}
}
